<template>
  <div>
    <Header />
    <div class="page-title">
      <div class="container">
        <div class="title text-black">
          Política de Proteção de Dados Tradecorp
        </div>
      </div>
    </div>

    <section>
      <div class="container">
        <div class="sub-title">
          Compromisso de Proteção de Dados e Privacidade
        </div>
        <p class="text-desc mt-1">
          A Tradecorp do Brasil, doravante denominada “EMPRESA”, cumpre com
          todas as leis aplicáveis no âmbito da privacidade e proteção de dados
          pessoais e da segurança da informação aplicáveis, em especial a Lei n.
          13.709 de 14 de agosto de 2018, Lei Geral de Proteção de Dados ou
          LGPD.
        </p>
        <p class="text-desc mt-1">
          A EMPRESA procedeu à implementação de um Sistema de Proteção de Dados
          Pessoais e de um Sistema de Segurança da Informação, de forma a
          garantir a conformidade normativa e a demonstração ou evidenciação da
          responsabilidade institucional em matéria de proteção de dados e
          segurança da informação, implementando todas as medidas técnicas e
          organizativas necessárias consideradas adequadas, em cumprimento às
          leis aplicáveis, em especial a LGPD.
        </p>
        <p class="text-desc mt-1">
          Para qualquer esclarecimento ou informação adicional ou para exercício
          de direitos neste âmbito, é favor contactar com o Encarregado da
          Proteção de Dados da EMPRESA através do e-mail:
          <a class="link" :href="'mailto:dpo.brasil@rovensa.com'"
            >dpo.brasil@rovensa.com</a
          >.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Definições</div>
        <div class="sub-title-2">Dados pessoais</div>
        <p class="text-desc">
          “Dados pessoais”, informação relativa a uma pessoa singular
          identificada ou identificável (“titular dos dados”); é considerada
          identificável uma pessoa singular que possa ser identificada, direta
          ou indiretamente, em especial por referência a um identificador. São
          considerados identificadores pessoais, por exemplo, um nome, um número
          de identificação, dados de localização, identificadores por via
          eletrônica ou a um ou mais elementos específicos da identidade física,
          fisiológica, genética, mental, econômica, cultural ou social dessa
          pessoa singular.
        </p>

        <div class="sub-title-2">Tratamento de Dados Pessoais</div>
        <p class="text-desc">
          “Tratamento”, uma operação ou um conjunto de operações efetuadas sobre
          dados pessoais ou sobre conjuntos de dados pessoais, por meios
          automatizados ou não automatizados, tais como a coleta, o registo, a
          organização, a estruturação, a conservação, a adaptação ou alteração,
          a recuperação, a consulta, a utilização, a divulgação por transmissão,
          difusão ou qualquer outra forma de disponibilização, a comparação ou
          interconexão, a limitação, o apagamento ou a destruição.
        </p>

        <div class="sub-title-2">Cookies</div>
        <p class="text-desc">
          “Cookies” são pequenos arquivos de texto com informação considerada
          como relevante que os dispositivos utilizados para o acesso
          (computadores, celulares ou dispositivos móveis portáteis) carregam,
          através do navegador de internet (browser), quando um site online é
          visitado pelo Cliente ou Utilizador.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Entidade Controladora</div>
        <p class="text-desc mt-1">
          A TRADECORP DO BRASIL COMERCIO DE INSUMOS AGRICOLAS LTDA, Pessoa
          Jurídica com o CNPJ 04.997.059/0001-57 , doravante designada por
          “EMPRESA”, é a entidade responsável pelos sites online, pelos sistemas
          ou aplicações informatizadas, adiante designados por canais ou
          aplicações, através dos quais os Usuários, Destinatários do Serviço ou
          Clientes têm acesso remoto aos serviços da EMPRESA que são
          apresentados ou prestados, em qualquer momento, através dos mesmos,
          sendo a entidade considerada Controladora dos dados pessoais.
        </p>
        <p class="text-desc mt-1">
          A utilização dos canais, sistemas ou aplicações por qualquer
          Utilizador, Destinatário do Serviço ou Cliente pode implicar a
          realização de operações de tratamento de dados pessoais, cuja
          proteção, privacidade e segurança pela EMPRESA, enquanto entidade
          responsável pelo respectivo tratamento, está assegurada, de acordo com
          os termos desta Política de Proteção de Dados.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Coleta e Tratamento de Dados Pessoais</div>
        <p class="text-desc mt-1">
          A EMPRESA procede ao tratamento dos dados pessoais estritamente
          necessários à disponibilização da informação e ao funcionamento dos
          seus canais, de acordo com as utilizações realizadas pelos Usuários,
          Destinatários do Serviço ou Clientes, seja daqueles que são fornecidos
          para efeitos de registo de pedidos ou obtenção de informações, seja
          daqueles que são fornecidos para efeitos de adesão àqueles canais,
          seja daqueles que resultam da utilização dos serviços prestados pela
          EMPRESA através dos mesmos, como sejam os acessos, consultas,
          instruções, transações e outros registos relativos à sua utilização.
        </p>
        <p class="text-desc mt-1">
          Em especial, a utilização ou a ativação de determinadas
          funcionalidades dos canais podem implicar o tratamento de vários
          identificadores pessoais diretos ou indiretos, tais como o nome, o
          endereço de residência, os contatos, os endereços dos dispositivos ou
          a localização geográfica, sempre que haja para tal o expresso
          consentimento do Utilizador, Destinatário do Serviço ou do Cliente,
          sempre que tal seja necessário para gestão da relação contratual ou
          prossecução de interesses legítimos ou, finalmente, para efeitos de
          cumprimento de obrigações legais ou regulatórias.
        </p>
        <p class="text-desc mt-1">
          Em todos os casos, os Usuários, Destinatários do Serviço ou Clientes
          serão sempre informados da necessidade de acesso de tais dados para a
          utilização das funcionalidades dos canais em causa.
        </p>
        <p class="text-desc mt-1">
          Os dados pessoais coletados pela EMPRESA são tratados eletronicamente,
          em determinados casos de forma automatizada, incluindo o processamento
          de arquivos ou a definição de perfis e no âmbito da gestão do
          relacionamento pré-contratual, contratual ou pós-contratual com os
          Usuários, Destinatários do Serviço ou Clientes, nos termos das normas
          nacionais e comunitárias em vigor.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">
          Categorias de Dados Pessoais Tratados e de Titulares
        </div>
        <p class="text-desc mt-1">
          As categorias ou tipos de dados pessoais que são objeto de tratamento
          são geralmente as seguintes: <br />
          - dados de identificação; <br />
          - dados de contato; <br />
          - dados profissionais; <br />
          - dados de faturamento ; <br />
          - dados de tráfego e controle de acessos.
        </p>
        <p class="text-desc mt-1">
          Nos diferentes estabelecimentos do Controladora poderão ainda ser
          objeto de tratamento dados de caráter biométrico, processados através
          dos sistemas de vídeo vigilância ou outros sistemas biométricos que
          estejam instalados.
        </p>
        <p class="text-desc mt-1">
          A listagem detalhada de categorias de dados pessoais e das categorias
          de titulares de dados constam do Registro das Operações de Tratamento
          de Dados de cada uma das concretas atividades de tratamento.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Princípios Jurídicos</div>
        <p class="text-desc mt-1">
          Todas as operações de tratamento de dados cumprem com os princípios
          jurídicos fundamentais no âmbito da proteção de dados e privacidade,
          designadamente quanto à sua circulação, licitude, lealdade,
          transparência, finalidade, minimização, conservação, exatidão,
          integridade e confidencialidade, estando a EMPRESA disponível para
          demonstrar a sua responsabilidade perante o titular dos dados ou
          qualquer outra entidade terceira que tenha um interesse legítimo nesta
          matéria.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Fundamentos de Legitimidade</div>
        <p class="text-desc mt-1">
          Todas as operações de tratamento de dados realizadas pela EMPRESA têm
          um fundamento de legitimidade, designadamente, quer pelo fato de o
          titular dos dados ter dado o seu consentimento para o tratamento dos
          seus dados pessoais para uma ou mais finalidades específicas, quer
          pelo fato de o tratamento ser considerado necessário para a execução
          de um contrato no qual o titular dos dados é parte ou para diligências
          pré-contratuais a pedido do titular dos dados, quer pelo fato de o
          tratamento ser necessário para o cumprimento de uma obrigação legal ou
          regulatória a que o Controlador esteja sujeito, quer seja para o
          exercício regular de direitos do Controlador em processo judicial,
          administrativo ou arbitral, quer seja para a proteção da vida ou da
          incolumidade física do titular ou de terceiros, quer seja para a
          tutela da saúde, exclusivamente em procedimento realizado por
          profissionais de saúde ou autoridade sanitária, quer seja para a
          proteção do crédito, quer para efeito de interesse público, quer pelo
          fato de o tratamento ser considerado necessário para efeito de
          prossecução dos interesses legítimos perseguidos pela EMPRESA ou por
          terceiros.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Finalidade do Tratamento</div>
        <p class="text-desc mt-1">
          Todos os dados pessoais tratados no âmbito dos canais da EMPRESA
          destinam-se exclusivamente à disponibilização de informação aos
          Usuários, à gestão da informação pessoal dos Destinatários do Serviço
          considerada necessária para efeitos de gestão do relacionamento ou
          comunicação, bem como à prestação dos serviços aos Clientes e, em
          geral, à gestão do relacionamento pré- contratual, contratual ou
          pós-contratual com os Usuários, com os Destinatários dos Serviços ou
          com os Clientes.
        </p>
        <p class="text-desc mt-1">
          Os dados pessoais coletados poderão, ainda e eventualmente, ser objeto
          de tratamento para fins estatísticos, para ações de divulgação de
          informações ou promocionais e para ações de comunicação, nomeadamente
          para promover ações de divulgação de novas funcionalidades ou novos
          serviços, através de comunicação direta, seja por correspondência,
          seja por correio eletrônico, mensagens ou chamadas telefônicas ou
          qualquer outro serviço de comunicações eletrônicas.
        </p>
        <p class="text-desc mt-1">
          Estando sempre assegurada a prévia informação e a coleta da
          autorização expressa para estas últimas finalidades, os Usuários,
          Destinatários dos Serviços ou Clientes podem, a qualquer momento,
          exercer o seu direito de retirar o consentimento ou o seu direito de
          oposição à utilização dos seus dados pessoais para outras finalidades
          que extrapolem a gestão do relacionamento com o Controlador,
          designadamente para fins de prossecução de interesses legítimos, para
          o envio de comunicações informativas ou para a inclusão em listas ou
          serviços informativos, devendo, para tal, enviar um pedido escrito
          dirigido ao Comitê da Proteção de Dados da EMPRESA, de acordo com os
          procedimentos abaixo indicados.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Direito de Acesso</div>
        <p class="text-desc mt-1">
          Nos termos do princípio da lealdade e da transparência e para garantia
          do cumprimento do dever de informação, a EMPRESA concederá, mediante
          solicitação do titular de dados, acesso aos dados pessoais tratados
          pela EMPRESA. Estando tais informações acessíveis ao titular para
          consulta por meio de pedido ao Encarregado da Proteção de Dados.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Prazos de Conservação dos Dados</div>
        <p class="text-desc mt-1">
          Os dados pessoais serão objeto de conservação apenas pelo período
          necessário para as finalidades que motivaram a sua coleta ou o seu
          posterior tratamento, estando garantido o cumprimento de todas as
          normas jurídicas aplicáveis em matéria de arquivo e estando
          especificado o prazo de conservação concreto em cada uma das Registro
          das Operações de Tratamento de Dados.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Utilização de Cookies</div>
        <p class="text-desc mt-1">
          A utilização de Cookies pela EMPRESA é realizada nos termos e
          condições da Política de Cookies, acessível em qualquer ponto de
          atendimento da organização ou em
          <router-link class="link" target="_blank" to="/politica-de-cookies"
            >https://tradecorp.com.br/politica-de-cookies</router-link
          >.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Comunicação de Dados a Outras Entidades</div>
        <p class="text-desc mt-1">
          A disponibilização de informações ou a prestação dos serviços pela
          EMPRESA aos seus Usuários, Destinatários do Serviço ou Clientes
          através dos canais pode eventualmente implicar o recurso a serviços de
          terceiras entidades subcontratadas (operadores), incluindo entidades
          com sede fora do Brasil, para a prestação de determinados serviços,
          podendo tal implicar o acesso, por estas entidades, a esses dados
          pessoais.
        </p>
        <p class="text-desc mt-1">
          Nestas circunstâncias e sempre que tal seja necessário, a EMPRESA
          recorrerá apenas aos operadores que apresentem garantias suficientes
          de execução de medidas técnicas e organizativas adequadas de uma forma
          que o tratamento satisfaça os requisitos das normas aplicáveis,
          ficando tais garantias formalizadas em contrato assinado entre a
          EMPRESA e cada uma dessas terceiras entidades.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Destinatários de Dados</div>
        <p class="text-desc mt-1">
          Salvo no âmbito do cumprimento de obrigações legais, execução de
          contratos ou prossecução de interesses legítimos, em caso algum haverá
          comunicação de dados pessoais de Usuários, Destinatários do Serviço ou
          Clientes a terceiras entidades que não sejam operadores ou
          destinatários legítimos, não sendo realizada, também, qualquer outra
          comunicação para outras finalidades diferentes das referidas supra.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Transferências Internacionais de Dados</div>
        <p class="text-desc mt-1">
          Qualquer transferência de dados pessoais para um país terceiro ou uma
          organização internacional somente será realizada no quadro do
          cumprimento de obrigações legais ou garantida que seja a conformidade
          com as normas jurídicas comunitárias e nacionais aplicáveis nesta
          matéria.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Medidas de Segurança</div>
        <p class="text-desc mt-1">
          Tendo em conta as técnicas mais avançadas, os custos de aplicação e a
          natureza, o âmbito, o contexto e as finalidades do tratamento, bem
          como os riscos, de probabilidade e gravidade variável, para os
          Usuários, Destinatários do Serviço ou Clientes, a EMPRESA e todos as
          entidades que sejam seus operadores aplicam as medidas técnicas e
          organizativas adequadas para assegurar um nível de segurança adequado
          ao risco.
        </p>
        <p class="text-desc mt-1">
          Para o efeito, são adotadas diversas medidas de segurança, de forma a
          proteger os dados pessoais contra a sua difusão, perda, uso indevido,
          alteração, tratamento ou acesso não autorizado e de situações
          acidentais, bem como contra qualquer outra forma de tratamento
          inadequado ou ilícito.
        </p>
        <p class="text-desc mt-1">
          É da exclusiva responsabilidade dos Usuários, Destinatários do Serviço
          ou dos Clientes guardar segredo dos códigos de acesso, não os
          partilhando com terceiros, devendo, ainda, no caso particular das
          aplicações informáticas utilizadas para aceder aos canais, manter e
          conservar os dispositivos de acesso em condições de segurança e seguir
          as práticas de segurança aconselhadas pelos fabricantes e/ou
          operadoras, nomeadamente quanto à instalação e atualização dos
          necessários aplicativos de segurança, nomeadamente, entre outras,
          aplicações de antivírus.
        </p>
        <p class="text-desc mt-1">
          Verificando-se a necessidade de contratação de serviços de operadores
          que possam ter acesso aos dados pessoais dos Usuários, Destinatários
          do Serviço ou dos Clientes, os operadores da EMPRESA ficarão obrigados
          a adotar as medidas e os protocolos de segurança ao nível da
          organização e as medidas de caráter técnico necessários à proteção da
          confidencialidade e segurança dos dados pessoais, bem como a prevenir
          acessos não autorizados, perdas ou a destruição de dados pessoais.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">
          Exercício dos Direitos dos Titulares dos Dados Pessoais
        </div>
        <p class="text-desc mt-1">
          Os Usuários, Destinatários do Serviço ou Clientes da EMPRESA podem,
          enquanto titulares de dados pessoais, em qualquer momento, proceder ao
          exercício dos seus direitos de proteção de dados e de privacidade,
          designadamente dos direitos de confirmação da existência de
          tratamento, acesso, correção, anonimização, bloqueio ou eliminação,
          portabilidade dos dados, informação sobre uso compartilhado,
          informação sobre possibilidade de não fornecer consentimento,
          revogação do consentimento, nos termos e com as limitações previstas
          nas normas aplicáveis.
        </p>
        <p class="text-desc mt-1">
          Qualquer pedido de exercício de direitos de proteção de dados e de
          privacidade deve ser dirigido, por escrito, pelo respectivo titular,
          para o Encarregado da Proteção de Dados, de acordo com o procedimento
          e contato descrito abaixo.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Reclamações ou Sugestões</div>
        <p class="text-desc mt-1">
          Os Usuários, Destinatários do Serviço ou Clientes têm o direito de
          apresentar reclamação, quer através do registo da reclamação no
          registro interno de Solicitações do Titular, quer através da
          apresentação de reclamação às autoridades reguladoras – podendo, neste
          último caso, apresentar petição ou queixa diretamente à Comissão
          Nacional de Proteção de Dados através dos contatos disponíveis em
          <a
            class="link"
            target="_blank"
            rel="noreferrer"
            href="https://www.gov.br/anpd/pt-br"
            >www.gov.br/anpd/pt-br</a
          >.
        </p>
        <p class="text-desc mt-1">
          Os Usuários, Destinatários do Serviço ou dos Clientes podem, também,
          proceder à realização de sugestões através de correio eletrônico
          enviado para o Encarregado da Proteção de Dados através do correio
          eletrônico
          <a class="link" href="mailto:dpo.brasil@rovensa.com"
            >dpo.brasil@rovensa.com</a
          >.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Comunicação de Incidentes</div>
        <p class="text-desc mt-1">
          A EMPRESA tem implementado um sistema de gestão de incidentes no
          âmbito da proteção de dados e segurança da informação.
        </p>
        <p class="text-desc mt-1">
          Caso algum Utilizador, Destinatário do Serviço ou Cliente pretenda
          comunicar a ocorrência de uma qualquer situação de violação de dados
          pessoais, que provoque, de modo acidental ou ilícito, a destruição, a
          perda, a alteração, a divulgação ou o acesso, não autorizados, a dados
          pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo
          de tratamento, pode entrar em contato com o Encarregado da Proteção de
          Dados ou utilizar os contatos gerais da EMPRESA.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Alteração da Política de Proteção de Dados</div>
        <p class="text-desc mt-1">
          De forma a garantir a respetiva atualização, desenvolvimento e
          melhoria contínua, a EMPRESA pode, a qualquer momento, proceder às
          alterações, que sejam consideradas adequadas ou necessárias, a esta
          Política de Proteção de Dados, estando assegurada a sua publicação nos
          diferentes canais para garantir a transparência e informação aos
          Usuários, Destinatários do Serviço e Clientes.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Consentimento Expresso e Aceitação</div>
        <p class="text-desc mt-1">
          Os termos da Política de Proteção de Dados são complementares dos
          termos e disposições, em matéria de dados pessoais, previstas nas
          Condições Específicas de Utilização de cada um dos canais de
          comunicação da EMPRESA.
        </p>
        <p class="text-desc mt-1">
          A disponibilização livre, específica e informada dos dados pessoais
          pelo respectivo titular implica o conhecimento e aceitação das
          condições constantes desta Política, considerando-se que, pelo fato de
          utilizar os canais ou pelo fato de disponibilizar os seus dados
          pessoais, os Usuários, Destinatários do Serviço e os Clientes estão a
          autorizar expressamente o seu tratamento, de acordo com as regras
          definidas em cada um dos canais ou instrumentos de coleta aplicáveis.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Políticas de Proteção de Dados Especiais</div>
        <p class="text-desc mt-1">
          Com um compromisso de transparência e de informação e para garantir a
          adequação da Política de Proteção de Dados às diferentes operações de
          tratamento de dados realizadas e, sobretudo, às diferentes categorias
          de titulares de dados, a EMPRESA pode desenvolver Políticas de
          Proteção de Dados de caráter especial, tais como, por exemplo: <br />
          - a Política de Proteção de Dados em Contexto Laboral; <br />
          - a Política de Proteção de Dados na Gestão de Candidaturas; <br />
          - a Política de Proteção de Dados de Colaboradores de Fornecedores;
          <br />
          - a Política de Cookies.
        </p>
        <p class="text-desc mt-1">
          Estas políticas especiais são disponibilizadas diretamente às
          respectivas categorias de titulares, estando disponíveis para consulta
          por pedido ao Encarregado da Proteção de Dados.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Encarregado de Proteção de Dados</div>
        <p class="text-desc mt-1">
          Para o exercício de qualquer tipo de direitos de proteção de dados e
          de privacidade ou para qualquer assunto referente aos temas da
          proteção de dados e segurança da informação, os Usuários,
          Destinatários do Serviço e Clientes que interagem com a EMPRESA podem
          entrar em contato com o Responsável da Proteção de Dados através do
          correio eletrônico
          <a class="link" href="mailto:dpo.brasil@rovensa.com"
            >dpo.brasil@rovensa.com</a
          >, descrevendo o assunto do pedido e indicando um endereço de correio
          eletrônico, um endereço de contato telefônico ou um endereço de
          correspondência para resposta.
        </p>
        <p class="text-desc mt-1">
          Para qualquer outro efeito, podem ser utilizados os seguintes contatos
          gerais da EMPRESA: <br />
          - Endereço de e-mail:
          <a class="link" href="mailto:dpo.brasil@rovensa.com"
            >dpo.brasil@rovensa.com</a
          >; <br />
          - Endereço Postal: Endereço Postal: Rua Senador Feijó, n° 131, Conj.
          111, Sé, São Paulo, SP, Brasil — CEP: 01.006- 001.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Versões da Política da Proteção de Dados</div>
        <p class="text-desc mt-1">Versão desta Política: Versão 1.0.</p>
        <p class="text-desc mt-1">Data: 18/05/2022</p>
        <p class="text-desc mt-1">
          Para consultar as anteriores versões da Política de Proteção de Dados,
          se houver, favor enviar pedido por e-mail para o endereço
          <a class="link" href="mailto:dpo.brasil@rovensa.com"
            >dpo.brasil@rovensa.com</a
          >.
        </p>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "Terms",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
// section {
//   padding: 40px 0 75px 0 !important;
// }

.sub-title {
  position: relative;
  font-family: fontRegular;
  font-size: 2em;
  font-weight: lighter;
  color: #000;
}

.sub-title-2 {
  position: relative;
  font-family: fontRegular;
  font-size: 1.2em;
  font-weight: lighter;
  color: #000;
  margin: 15px 0 10px 0;
}

.link {
  color: blue;
  font-family: fontLight;
  font-size: 0.95em;
}
</style>
